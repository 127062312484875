<style lang="less">
.c_answer_write {
  height: 100vh;
  background: #f6fafe;
  ul {
    list-style: none;
  }
  .write_container {
    padding: 20px;
  }
  .message_box {
    padding: 20px;
    box-shadow: @shadow;
    background: #fff;
    .message {
      display: flex;
      align-items: center;
      flex-flow: row wrap;
      p {
        font-weight: bold;
        margin-bottom: 20px;
        margin-right: 20px;
        font-size: 20px;
        span:last-child {
          color: @mainColor;
        }
      }
    }
    .desc {
      font-weight: bold;
      font-size: 20px;
    }
  }
  .answer_list {
    margin-top: 20px;
    background: #fff;
    min-height: 100px;
    list-style: none;
    .el-collapse {
      border-top: 0;
      .el-collapse-item__header {
        padding: 10px 20px 0;
        font-weight: 500;
        border: 0;
        font-size: 16px;
      }
      .el-collapse-item__wrap {
        border-bottom: 0;
      }
      .el-collapse-item__content {
        padding-bottom: 0;
        .content {
          font-weight: 500;
          font-size: 16px;
          .desc {
            background: #f2f2f2;
            padding: 10px 20px;
          }
          .item {
            padding: 10px 0px;
            border-bottom: 1px solid @borderBottomColor;
            .title {
              padding: 10px 20px;
              font-size: 16px;
              span:nth-child(2) {
                display: inline-block;
                margin: 0 8px;
              }
            }
            .radio_options {
              margin-left: 50px;
              li {
                margin-bottom: 6px;
                .el-radio .el-radio__label {
                  font-size: 16px;
                }
              }
            }
            .input_options {
              margin-left: 50px;
              width: 50%;
              min-width: 220px;
              textarea {
                resize: none;
              }
            }
          }
        }
      }
    }
    .none {
      text-align: center;
      color: #aaa;
      font-weight: 500;
      line-height: 100px;
    }
  }
}
</style>
<template>
  <!-- 作答 -->
  <div class="c_answer_write">
    <StudentHeader :videoFlag="true" />
    <div class="write_container" v-loading="loading">
      <div class="message_box">
        <div class="message">
          <p>
            <span>考试名称：</span><span>{{ items.examName }}</span>
          </p>
          <p>
            <span>试卷名称：</span><span>{{ items.paperName }}</span>
          </p>
          <p>
            <span>试卷总分：</span><span>{{ items.totalScore }}</span>
          </p>
          <p v-if="timeMsg">
            <span>考试时长：</span><span>{{ timeMsg }}</span>
          </p>
          <p v-if="short.date">
            <span>{{ short.date }}</span>
          </p>
        </div>
        <div class="desc">要求：{{ items.desc }}</div>
      </div>

      <div class="answer_list">
        <el-collapse v-model="active" v-if="bigQuestions.length">
          <el-collapse-item
            :name="item.id"
            v-for="(item, index) in bigQuestions"
            :key="index"
          >
            <template slot="title">
              <span class="collapse_header" v-html="item.questionName"></span>
            </template>
            <div class="content">
              <p class="desc">答题要求：{{ item.questionDesc }}</p>
              <div
                class="item"
                v-for="(child, i) in item.questionList"
                :key="i"
              >
                <p class="title">
                  <span>{{ i + 1 }}.</span>
                  <span>({{ child.itemScore }}分)</span>
                  <span v-html="child.title"></span>
                </p>
                <ul
                  class="radio_options"
                  v-if="child.options && child.options.length"
                >
                  <li v-for="(r, rIndex) in child.options" :key="rIndex">
                    <el-radio :label="r.itemKey" v-model="child.info"
                      >{{ r.itemKey }}. {{ r.itemVal }}</el-radio
                    >
                  </li>
                </ul>
                <div v-else class="input_options">
                  <el-input
                    v-if="items.copyFlag === 0"
                    type="textarea"
                    :autosize="{ minRows: 4, maxRows: 6 }"
                    v-model="child.info"
                  ></el-input>
                  <el-input
                    v-else
                    @paste.native.capture.prevent="handleFalse"
                    @copy.native.capture.prevent="handleFalse"
                    @cut.native.capture.prevent="handleFalse"
                    type="textarea"
                    :autosize="{ minRows: 4, maxRows: 6 }"
                    v-model="child.info"
                  ></el-input>
                </div>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
        <div v-else class="none">暂无试卷信息</div>
      </div>

      <el-button
        v-if="bigQuestions.length"
        @click="submit"
        :loading="btnLoading"
        style="margin-top: 20px"
        type="primary"
        >交卷</el-button
      >
    </div>
  </div>
</template>

<script>
import StudentHeader from "@/components/student/Header.vue";
import { ExamPreview, EduCourseStudentExamAnswer } from "@/libs/api/online";
import { dateTimeFormat } from "@/tools/date";
export default {
  name: "c_answer_write",
  components: { StudentHeader },
  data() {
    return {
      loading: false,
      btnLoading: false,
      items: {
        paperName: "", // 试卷名称
        examName: "", // 考试名称
        totalScore: 0, // 试卷总分
        desc: "", // 要求
        copyFlag: 0, // 禁止复制粘贴 0可复制 1不可复制
      },
      bigQuestions: [
        // {
        //   questionScore: 30, //答题分数
        //   id: 1,
        //   questionName: "一. 单选题", //大题名称
        //   questionList: [
        //     {
        //       questionKey: "日本", //答案
        //       itemScore: 10, //题目分数
        //       questionAnalyze: "解析：东京是日本首都", //解析
        //       options: [
        //         {
        //           id: 1,
        //           questionId: 1, //题目id
        //           itemKey: "A", //选项键
        //           itemVal: "美国", //选项值
        //           sort: 1,
        //           enabled: 1,
        //           delFlag: 0,
        //           createTime: "2021-02-25T07:12:05.000+0000",
        //           updateTime: "2021-02-25T07:12:22.000+0000",
        //         },
        //         {
        //           id: 2,
        //           questionId: 1, //题目id
        //           itemKey: "B", //选项键
        //           itemVal: "中国", //选项值
        //           sort: 2,
        //           enabled: 1,
        //           delFlag: 0,
        //           createTime: "2021-02-25T07:12:17.000+0000",
        //           updateTime: "2021-02-25T07:12:24.000+0000",
        //         },
        //         {
        //           id: 3,
        //           questionId: 1, //题目id
        //           itemKey: "C", //选项键
        //           itemVal: "泰国", //选项值
        //           sort: 3,
        //           enabled: 1,
        //           delFlag: 0,
        //           createTime: "2021-02-25T07:12:37.000+0000",
        //           updateTime: null,
        //         },
        //         {
        //           id: 4,
        //           questionId: 1, //题目id
        //           itemKey: "D", //选项键
        //           itemVal: "日本", //选项值
        //           sort: 4,
        //           enabled: 1,
        //           delFlag: 0,
        //           createTime: "2021-02-25T07:12:49.000+0000",
        //           updateTime: null,
        //         },
        //       ],
        //       id: 1,
        //       title: "东京是哪个国家的首都？", //题干
        //     },
        //     {
        //       questionKey: "中国", //答案
        //       itemScore: 10, //题目分数
        //       questionAnalyze: "解析：北京是中国首都", //解析
        //       options: [
        //         {
        //           id: 1,
        //           questionId: 1, //题目id
        //           itemKey: "A", //选项键
        //           itemVal: "美国", //选项值
        //           sort: 1,
        //           enabled: 1,
        //           delFlag: 0,
        //           createTime: "2021-02-25T07:12:05.000+0000",
        //           updateTime: "2021-02-25T07:12:22.000+0000",
        //         },
        //         {
        //           id: 2,
        //           questionId: 1, //题目id
        //           itemKey: "B", //选项键
        //           itemVal: "中国", //选项值
        //           sort: 2,
        //           enabled: 1,
        //           delFlag: 0,
        //           createTime: "2021-02-25T07:12:17.000+0000",
        //           updateTime: "2021-02-25T07:12:24.000+0000",
        //         },
        //         {
        //           id: 3,
        //           questionId: 1, //题目id
        //           itemKey: "C", //选项键
        //           itemVal: "泰国", //选项值
        //           sort: 3,
        //           enabled: 1,
        //           delFlag: 0,
        //           createTime: "2021-02-25T07:12:37.000+0000",
        //           updateTime: null,
        //         },
        //         {
        //           id: 4,
        //           questionId: 1, //题目id
        //           itemKey: "D", //选项键
        //           itemVal: "日本", //选项值
        //           sort: 4,
        //           enabled: 1,
        //           delFlag: 0,
        //           createTime: "2021-02-25T07:12:49.000+0000",
        //           updateTime: null,
        //         },
        //       ],
        //       id: 2,
        //       title: "北京是哪个国家的首都？", //题干
        //     },
        //   ],
        // },
        // {
        //   questionScore: 10, //答题分数
        //   id: 2,
        //   questionName: "二. 判断题", //大题名称
        //   questionList: [
        //     {
        //       questionKey: "错误", //答案
        //       itemScore: 10, //题目分数
        //       questionAnalyze: "解析：东京是日本首都", //解析
        //       options: [
        //         {
        //           id: 5,
        //           questionId: 2, //题目id
        //           itemKey: "1", //选项键
        //           itemVal: "正确", //选项值
        //           sort: 1,
        //           enabled: 1,
        //           delFlag: 0,
        //           createTime: "2021-02-25T07:13:39.000+0000",
        //           updateTime: "2021-02-25T07:14:14.000+0000",
        //         },
        //         {
        //           id: 6,
        //           questionId: 2, //题目id
        //           itemKey: "2", //选项键
        //           itemVal: "错误", //选项值
        //           sort: 2,
        //           enabled: 1,
        //           delFlag: 0,
        //           createTime: "2021-02-25T07:14:09.000+0000",
        //           updateTime: null,
        //         },
        //       ],
        //       id: 3,
        //       title: "东京是美国的吗？", //题干
        //     },
        //   ],
        // },
        // {
        //   questionScore: 20, //答题分数
        //   id: 3,
        //   questionName: "三. 填空题", //大题名称
        //   questionList: [
        //     {
        //       questionKey: "不会", //答案
        //       itemScore: 10, //题目分数
        //       questionAnalyze: "解析：不", //解析
        //       id: 4,
        //       title: "如果我是DJ你会爱我吗？", //题干
        //     },
        //   ],
        // },
      ],
      timer: null, // 考试计时器
      timeMsg: "", // 考试时长
      // 最短时长
      short: {
        timer: null,
        date: "",
        disabled: true,
      },
      startTime: null,
      endTime: null,
    };
  },
  computed: {
    active() {
      return this.bigQuestions.map((m) => m.id);
    },
  },
  mounted() {
    const { id } = this.$route.query;
    this.loading = true;
    ExamPreview({ id })
      .then((res) => {
        this.loading = false;
        this.items = res.data;
        this.bigQuestions = res.data.bigQuestions;

        this.startTime = dateTimeFormat(new Date()); // 考试开始时间
      })
      .catch(() => {
        this.loading = false;
      });
    // 最短时长，时长未结束不能提交试卷
    let shortDuration = sessionStorage.stu_exam_shortDuration
      ? JSON.parse(sessionStorage.stu_exam_shortDuration)
      : null;
    shortDuration && this.shortTimeout(shortDuration);
    // 考试倒计时
    let time = sessionStorage.writeTime
      ? JSON.parse(sessionStorage.writeTime)
      : null;
    time && this.answerTimeout(time);
  },
  methods: {
    submit() {
      if (this.short.disabled) {
        return this.$message.error("考试最短时长结束前，您还无法提交试卷");
      }
      this.$confirm("确定要提交试卷吗？", "试卷提交", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.submitService();
        })
        .catch(() => {});
    },
    submitService() {
      this.endTime = dateTimeFormat(new Date()); // 考试开始时间
      let useDuration = this.difference(this.startTime, this.endTime); // 计算考试时间
      const examSetId = this.$route.query.id; // 考试id
      let status = 0; // 是否全部做完题目 0是 1否
      let eduTestPaperItems = []; // 题目集合
      this.bigQuestions.forEach((big) => {
        big.questionList.forEach((item) => {
          eduTestPaperItems.push({
            questionId: item.id,
            info: item.info || "",
            itemScore: item.itemScore,
          });
          if (!item.info) status = 1;
        });
      });

      let data = {
        examSetId,
        status,
        useDuration: useDuration.minutes,
        eduTestPaperItems,
      };
      this.EduCourseStudentExamAnswer(data);
    },
    EduCourseStudentExamAnswer(data) {
      this.btnLoading = true;
      EduCourseStudentExamAnswer(data)
        .then((res) => {
          this.btnLoading = false;
          this.$message.success("试卷提交成功，即将关闭页面...");
          window.opener.postMessage("color", window.opener.location.origin);
          sessionStorage.removeItem("writeTime");
          setTimeout(() => window.close(), 2000);
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    // 时间差计算
    difference(beginTime, endTime) {
      var dateBegin = new Date(beginTime);
      var dateEnd = new Date(endTime);
      var dateDiff = dateEnd.getTime() - dateBegin.getTime(); //时间差的毫秒数
      var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)); //计算出相差天数
      var leave1 = dateDiff % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      var hours = Math.floor(leave1 / (3600 * 1000)); //计算出小时数
      //计算相差分钟数
      var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      var minutes = Math.floor(leave2 / (60 * 1000)); //计算相差分钟数
      //计算相差秒数
      var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
      var seconds = Math.round(leave3 / 1000);
      return { minutes, seconds };
    },
    answerTimeout(minutes) {
      if (minutes <= 0) return;
      let max = 60 * minutes; // 按秒计算
      this.timer = setInterval(() => {
        if (max >= 0) {
          let m = Math.floor(max / 60);
          let s = Math.floor(max % 60);
          this.timeMsg =
            "距离考试结束还有" +
            m +
            "分" +
            s +
            "秒，计时结束系统将自动为您提交";
          if (max == 5 * 60) this.$message.warning("还剩5分钟");
          --max;
        } else {
          clearInterval(this.timer);
          this.$message.warning("考试时间结束！系统将自动为您提交");
          setTimeout(() => this.submitService(), 1000);
        }
      }, 1000);
    },
    // 最早交卷时间
    shortTimeout(minutes) {
      if (minutes <= 0) return;
      let max = 60 * minutes; // 按秒计算
      this.timer = setInterval(() => {
        if (max >= 0) {
          let m = Math.floor(max / 60);
          let s = Math.floor(max % 60);
          this.short.date = "您还有" + m + "分" + s + "秒可以提交试卷";
          --max;
        } else {
          clearInterval(this.short.timer);
          this.short.date = "";
          this.short.disabled = false;
        }
      }, 1000);
    },
    handleFalse() {
      return false;
    },
  },
};
</script>